import React from "react";
import ComingSoonComponent from "../../components/ComingSoonComponent";
import { Mail, Phone } from "lucide-react";

const Contact = () => {
  return (
    <div className="flex items-center gap-10 h-full min-h-screen xl:h-screen px-4 lg:px-16 lg:flex-row flex-col py-20">
      <div className="flex flex-col gap-5 w-full">
        <div className="flex flex-col gap-5 items-center justify-center lg:items-start lg:justify-start">
          <div className="flex flex-col gap-2 text-center lg:text-start">
            <p className="text-xl text-white">Want to get in touch?</p>

            <p className="max-w-[500px] text-gray-400">
              Interested in Vuldoser solutions, have questions, or want to get
              help from our team? Check out all available ways you can reach us.
            </p>
          </div>

          <div className="flex flex-col gap-2 ">
            <div className="flex items-start gap-2 text-white">
              <Phone className="text-xs w-4 h-4 mt-1" />

              <div className="flex items-start gap-2">
                <p>Mob:</p>

                <div>
                  <p>
                    <a
                      href="tel:+48453335585"
                      className="hover:text-primary-500"
                    >
                      +48453335585
                    </a>
                  </p>

                  <p>
                    <a
                      href="tel:+994502902348"
                      className="hover:text-primary-500"
                    >
                      +994502902348
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2 text-white">
              <Mail className="text-sm w-4 h-4" />

              <p>
                Email:{" "}
                <a
                  href="mailto:info@vuldoser.com"
                  className="hover:text-primary-500"
                >
                  info@vuldoser.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full block">
        <img
          src="./assets/about.jpg"
          alt="About"
          className="h-[400px] lg:h-[450px] xl:h-[700px] rounded-2xl object-cover"
        />
      </div>
    </div>
  );
};

export default Contact;
