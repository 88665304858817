import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { RiMenu4Fill } from "react-icons/ri";

const Menu = () => {
  const [menuActive, setmenuActive] = useState(false);

  return (
    <div className="w-full lg:px-[70px] md:px-[40px] px-[25px] py-4 flex items-center justify-between absolute">
      {/* Logo Section */}
      <Link to="/">
        <div className="flex items-center">
          <div>
            <img
              src={Logo}
              alt="Logo"
              className="lg:h-[50px] lg:w-[50px] lg:mr-[15px] mr-[10px] h-[30px] w-[30px]"
            />
          </div>
          <div>
            <h1 className="text-white font-medium text-base lg:text-2xl md:lg">
              Vuldoser
            </h1>
          </div>
        </div>
      </Link>

      {/* Menu Links */}
      <div>
        {/* Large Screen Menu */}
        <div className="text-white text-lg lg:text-xl hidden md:block">
          <ul className="flex gap-[30px]">
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {/* <li>
              <Link to="/support">Support</Link>
            </li> */}
            <li>
              <Link to="/documentation">Documentation</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
        {/* Small Screen Menu */}
        <div className="md:hidden block text-white">
          <div onClick={() => setmenuActive(!menuActive)}>
            {menuActive ? (
              <RiMenu4Fill className="text-2xl" />
            ) : (
              <RxHamburgerMenu className="text-2xl" />
            )}
          </div>
          {menuActive ? (
            <div className="py-[10px] px-[20px] absolute rounded-2xl right-7 top-12 bg-[#151f35] flex items-center justify-center">
              <ul className="text-[#7D4CD9]">
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/support">Support</Link>
                </li>
                <li>
                  <Link to="/documentation">Documentation</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Menu;
