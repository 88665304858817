import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import Contact from "./Contact";
import Support from "./Support";
import Documentation from "./Documentation";
import AboutUs from "./AboutUs";
import Error404 from "../../components/Error404";
import Menu from "../../components/Menu";

const Home = () => {
  return (
    <div>
      <Menu />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="contact" element={<Contact />} />
        {/* <Route path="support" element={<Support />} /> */}
        <Route path="documentation" element={<Documentation />} />
        <Route path="about" element={<AboutUs />} />
        <Route
          path="*"
          element={
            <Error404
              screenHeight={
                "h-screen"
              }
              to={"/"}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Home;
